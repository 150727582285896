exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-tools-catan-board-generator-js": () => import("./../../../src/pages/tools/catan-board-generator.js" /* webpackChunkName: "component---src-pages-tools-catan-board-generator-js" */),
  "component---src-pages-tools-image-to-pixelization-js": () => import("./../../../src/pages/tools/image-to-pixelization.js" /* webpackChunkName: "component---src-pages-tools-image-to-pixelization-js" */),
  "component---src-pages-tools-index-js": () => import("./../../../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-multi-file-rename-js": () => import("./../../../src/pages/tools/multi-file-rename.js" /* webpackChunkName: "component---src-pages-tools-multi-file-rename-js" */),
  "component---src-pages-tools-progress-checksheet-js": () => import("./../../../src/pages/tools/progress-checksheet.js" /* webpackChunkName: "component---src-pages-tools-progress-checksheet-js" */),
  "component---src-pages-tools-sample-image-generator-js": () => import("./../../../src/pages/tools/sample-image-generator.js" /* webpackChunkName: "component---src-pages-tools-sample-image-generator-js" */),
  "component---src-pages-tools-two-page-viewer-js": () => import("./../../../src/pages/tools/two-page-viewer.js" /* webpackChunkName: "component---src-pages-tools-two-page-viewer-js" */),
  "component---src-pages-tools-video-to-webpgif-converter-js": () => import("./../../../src/pages/tools/video-to-webpgif-converter.js" /* webpackChunkName: "component---src-pages-tools-video-to-webpgif-converter-js" */),
  "component---src-templates-about-template-js": () => import("./../../../src/templates/AboutTemplate.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-blog-category-template-js": () => import("./../../../src/templates/BlogCategoryTemplate.js" /* webpackChunkName: "component---src-templates-blog-category-template-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/BlogListTemplate.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-tag-template-js": () => import("./../../../src/templates/BlogTagTemplate.js" /* webpackChunkName: "component---src-templates-blog-tag-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/BlogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

